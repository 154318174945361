.vision-container-image {
    width: 100%;
    height: 100%;
    /* background-color: #e5f5f6; */
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.vision-section-style {
    padding-top: 6rem;
    display: flex;
    /* padding-left: 50px; */
}

.tab-image {
    width:100%;
    margin: 51px 0 0;
}

.heading-tabs {
    position: relative;
    display: flex;
    overflow: hidden;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: wrap;
}

.tab-style {
    padding: 14px 35px 17px;
    margin: 0 20px 0 0;
    border-radius: 10px 10px 0 0;
}

.tab-content-style {
    padding: 10% 8% 11% 11%;
    margin: 0;
    color: white;
}

.inside-tab{
    font-weight: 700;
    line-height: 1.3;
    position: relative;
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    transition: .4s;
    text-align: center;
    color:white;
}

.first-color-tab {
    background-color: #4BC0C8;
}

.second-color-tab {
    background-color: #3A6EA5;
}

.third-color-tab {
    background-color: #7B3F98;
}

.first-tab-content-color {
    background-color: #4BC0C8;
}

.second-tab-content-color {
    background-color: #3A6EA5;
}

.third-tab-content-color {
    background-color: #7B3F98;
}

.tab-content-text {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 100%;
}

.hidden-tab {
    display: none;
}

.tab-content-text h2 {
    letter-spacing: -.03em;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 4rem;
    font-weight: 500;
    font-family: 'Basiercircle',sans-serif
}

.tab-content-text p{   
    font-family: Inter,sans-serif;
    font-size: 1.375rem;
    font-weight: 400;
    line-height: 1.45455;
}

@media (max-width: 768px) {
    
    .vision-container-image {
        object-fit: cover;
        object-position: 50% 50%;
        width: 100%;
        height: 400px;
        border-radius: 0;
    }

    .vision-section-style {
        padding-top: 0;
    }

    .tab-style{
        padding: 14px 20px 17px;
        margin: 0px 5px 0 0;
    }

    .vision-section-style {
        flex-direction: column;
    }
    .tab-content-text p{
        font-size: 1rem;
    }

    .tab-content-text h2 {
        font-size: 2rem;
    }
    .tab-image {
        height: 270px;
        margin: 0 0;
        margin-bottom: 50px;
    } 
}