.page-section {
  display: flex;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 10rem;

  gap: 20px;
  height: 800px;
  position: relative;
  background-color: #fff8ec;
}

.image-section {
  height: 75%;
  position: absolute;
  left: 57%;
  right: 0%;
  background-color: #fff8ec;
}

.image-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 100% 50%;
  max-width: 991px;
}

.info-section {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-top: 3.375rem;
  padding-bottom: 3.375rem;
  background-color: #fff8ec;
}

.section-title h1{
    letter-spacing: -.03em;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 4rem;
    font-weight: 500;
    font-family: 'Basiercircle',sans-serif
}

.section-content p{   
    font-family: Inter,sans-serif;
    font-size: 1.375rem;
    font-weight: 400;
    line-height: 1.45455;
}

.kindergarden-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding-left: 5%;
    padding-right: 5%;
    /* padding-top: 25rem; */
    padding-bottom: 4rem;
}

.link-blue-color {
    color:#302283;
}

.kindergarden-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}

.kindergarden-section img {
  width: 100%;
  object-fit: cover;
  object-position: 100% 50%;
  height: 700px;
}

.kindergarden-content {
    display: flex;
    flex-direction: column;
}

.sighisoara-banner {
    position: relative;
    width: 100vw;
    object-fit: cover;
    object-position: 100% 50%;
}

.album {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding-left: 10px;
}

.album-image {
    width: 100%;
    display: block;
}

.curriculum-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.curriculum-row {
    padding-top: 100px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 100px;
}

.curriculum-title{
    font-size: 25px;
    line-height: 20px;
    font-weight: 800;
    text-transform: capitalize;
    margin-bottom: 23px;
}

.curriculum-col{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 21%;
}

.curriculum-col p{
    font-size: 20px;
    line-height: 20px;
    font-weight: 800;
    text-transform: capitalize;
    margin-bottom: 23px;
}


.top-padding-10 {
    padding-top: 10rem;
}

.bottom-padding-10 {
    padding-bottom: 10rem;
}


.rhema-house-section {
    margin-top: 6rem;
    /* background-image: linear-gradient(#f7f7f7, #fff); */
    padding-left: 20px;
}

.rhema-house-content-section {
    padding-left: 20px;
}

.rhema-house-title {
    text-align: center;
    letter-spacing: -.03em;
    margin-top: 5rem;
    margin-bottom: 0;
    font-size: 2.5rem;
    font-weight: 500;
    font-family: 'Basiercircle',sans-serif
}
/* 
.rhema-house-section {
    
} */

.rhema-house-image img {
    border-radius: 15px 15px 15px 15px;
    height: 500px;
    width: 500px;
}

.flex-row-style {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
}

.afterschool-program{
    display: flex;
    flex-direction: column;
    margin-top: 6rem;
    background-image: linear-gradient(#f7f7f7, #fff);
    padding: 50px;
}

.afterschool-grid-images{
    display: grid;
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    min-height: 21.75rem;
    margin-bottom: 8rem;
}


.afterschool-program-title {
    text-align: center;
}

.afterschool-program-title h1 {
    font-size: 50px;
}

.afterschool-program-details {
    font-family: Inter, sans-serif;
    font-size: 1.375rem;
    font-weight: 400;
    line-height: 1.45455;
    padding-bottom: 6rem;
}

.text-and-image {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 50px;
    gap: 20px;
}

.sighisoara-section {
    background-image: linear-gradient(#f7f7f7, #fff);
    padding-top: 50px;
}

.bg-shape {
    width: 100%;
    height: 0;
}

.image-section-style {
    height: "500px";
    width: "700px";
}

@media (max-width: 768px) {
    .bg-shape {
        display: none;
    }

    .page-section {
        flex-direction: column;
        background-color: white;
        padding-top: 0;
        height: auto;
    }
    .info-section {
        background-color: white;
        width: 100%;
    }
    .flex-row-style {
        flex-direction: column;
    }
    .image-section{
        position: relative;
        left: 0;
    } 
    .rhema-house-section {
        margin-top: 0;
        padding-left: 0;
    }

    .rhema-house-content-section {
        padding-left: 0;
    }

    .afterschool-program-details {
        text-align: center;
    }

    .afterschool-program-title h1 {
        font-size: 30px;
    }

    .afterschool-grid-images {
        grid-template-columns: 1fr;
        min-height: 53.75rem;
        margin-bottom: 3rem;
    }

    .text-and-image {
        flex-direction: column;
    }
    .image-section-style {
        height: 250px;
        width: 350px;
    }

    .rhema-house-image img  {
        width: 350px;
        height: 250px;
    }
}