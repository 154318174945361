.albesti-banner {
    position: relative;
    width: 100vw;
    object-fit: cover;
    object-position: 100% 50%;
    height: 500px;
}

.albesti-section {
    width: 1000px;
    text-align: center;
}

.albesti-vallery-section h1 {
    letter-spacing: -.03em;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 4rem;
    font-weight: 500;
    font-family: 'Basiercircle',sans-serif
}

.albesti-section p {
    font-family: Inter,sans-serif;
    font-size: 1.375rem;
    font-weight: 400;
    line-height: 1.45455;
}

.albesti-vallery-section  {
    padding-top: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.albesti-vallery-section h1 {
    text-align: center;
}

.albesti-vallery-section p {
    font-family: Inter,sans-serif;
    font-size: 1.375rem;
    font-weight: 400;
    line-height: 1.45455;
}

.text-image-section{
    padding-top: 6rem;
    padding-bottom: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
}

.pad-left {
    padding-left: 50px;
}

.pad-right {
    padding-right: 50px;
}

.pad-bottom {
    padding-bottom: 5rem;
}

.pad-top3 {
    padding-top: 3rem;
}

.pad-bottom3 {
    padding-bottom: 3rem;
}

.text-image-section img{
    width: 800px;
    height: 500px;
}

.albesti-grid-images{
    display: grid;
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    min-height: 21.75rem;
    /* margin-bottom: 8rem; */
}

.align-text-center {
    text-align: center;
}

@media (max-width: 768px)  {
    .albesti-grid-images {
    display: grid;
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    min-height: 19.75rem;
    }
}


