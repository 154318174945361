.donation-page-wrapper {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 10rem;
    padding-bottom: 4rem;
}

.donation-statement {
    display: flex;
    flex-direction: row;
    gap: 50px;
}

.donation-statement-content {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    width: 100%;
    padding-top: 3.375rem; 
    padding-bottom: 3.375rem; 
}

.donation-statement-content h2 {
    letter-spacing: -.03em;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 4rem;
    font-weight: 500;
    font-family: 'Basiercircle',sans-serif
}

.donation-statement-content p{
    font-family: Inter,sans-serif;
    font-size: 1.675rem;
    font-weight: 400;
    line-height: 1.45455;
}

.donation-statement-image {
    width: 100%;
}

.donation-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 100% 50%;
}

.donation-action {
    padding-top: 1rem;
    padding-bottom: 5rem;
}

.flag-image {
    width: 75%;
    height: 100px;
}

.donation-action-container {
    display: flex;
    flex-direction: row;
    gap: 200px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.donation-action-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #e7dedeef;
    width: 35%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: 0.3s;
    height: 15%;

}

.donation-action-wrapper:hover {
    cursor: pointer;
    background-color: #e7dede80;
    width: 40%;
}

.donation-action-wrapper:hover .donation-button-text{
    font-size: 1.5rem;
}

.donation-action-wrapper:hover .icon-bank {
    font-size: 4rem;
}

.donation-action-p {
    font-family: Inter,sans-serif;
    font-size: 1.375rem;
    font-weight: 400;
    line-height: 1.45455;
}


.donation-action-title{
    letter-spacing: -.03em;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 2rem;
    font-weight: 500;
    font-family: 'Basiercircle',sans-serif;
}

.donation-dialog-title {
    letter-spacing: -.03em;
    text-align: center;
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 4rem;
    font-weight: 500;
    font-family: 'Basiercircle',sans-serif;
    background-color: red;
    color:white;
}

.donation-button-text {
    font-family: Inter,sans-serif;
    font-size: 1.375rem;
    font-weight: 400;
    line-height: 1.45455;
    width: 100%;
    text-align: center;
    transition: 0.3s;
}

.icon-bank {
    padding-top: 50px;
    padding-bottom: 10px;
    transition: 0.3s;
    font-size: 3rem;
}

.image-choose {
    width: 400px;
    height: 500px;
}

.row-container {
    padding-top: 2rem;
}

.show-location {
    margin-top: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.show-location img {
    height: 100px;
    width: 100px;
}

.bank-transfer-container {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.bank-transfer-title p{
    font-family: Inter,sans-serif;
    font-size: 1.375rem;
    font-weight: 400;
    line-height: 1.45455;
}

.bank-transfer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bank-transfer-content p {
    font-family: Inter,sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.45455;
}

.footer-donations {
    width: 100%;
}

.donation-cards-list {
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 50px;
}

.donation-card {
    width: 500px;
    height: 500px;
    background-color: blue;
}

.donation-card-image {
    max-width: 600px;
}

.donation-card-image img{
    width: 100%;
    height: 100%;
}

.donation-button-box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.donation-dialog-options {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    gap: 20px;
}

.donation-dialog-container {
    padding-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

}

.dialog-bank-container {
    display: flex;
    gap: 20px;
}

.donation-section-title {
    text-align: center;
    letter-spacing: -.03em;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 4rem;
    font-weight: 500;
    font-family: 'Basiercircle',sans-serif
}

.select-position {
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.donations-links {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-left: 25%;
}

.donation-card-size {
    width: 500px;
}

@media (max-width: 768px) {
    .donation-card-size {
        width: 325px;
    }
}