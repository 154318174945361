.menu-list-links{
    /* position: relative; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 25px;
}

a {
    color:white;
    text-decoration: none;
}

.hover-underline-animation {
    display: inline-block;
    position: relative;
    color:white;
    text-decoration: none;
    cursor: default;
}

.hover-underline-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: white;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

.hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.dropdown-menu{
    position: absolute;
    display: inline-block;
    left: 0;
    top: 120%;
    /* width:190px; */
    background-color: #fff;
    border-radius: 0 0 2px 2px;
    box-shadow: 0 20px 40px rgba(0,0,0,.10);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease-in-out;
    padding: 0 10px;
    margin-top: 5px;
}

.dropdown-menu.active{
    opacity: 1;
    visibility: visible;
    top: 100%;
}

.dropdown-menu li {
    padding-bottom: 16px;
    display: block;
    float: none;
}

.dropdown-menu li:first-child {
    padding-top: 16px;
}

.menu-list-item {
    position: relative;
}

.menu-list-item:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    top: 100%;
}

.dropdown-item {
    display: inline-block;
    text-align: justify;
    color: #000;
    font-size: 16px;
    white-space: nowrap;
}

.dropdown-item::after {
    content: '';
    display: block;
    width:auto;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #000;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

.dropdown-item:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.submenu {
    position: absolute;
    display: inline-block;
    left: 75%;
    top: 0;
    /* width:190px; */
    background-color: #fff;
    border-radius: 0 0 2px 2px;
    /* box-shadow: 0 20px 40px rgba(0,0,0,.10); */
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease-in-out;
    padding: 0 10px;
    /* margin-top: 5px; */
}

.dropdown-submenu:hover .submenu {
    opacity: 1;
    visibility: visible;
    left: 100%;
}

.hamburger {
    display: none;
}

.showArrow {
    display: none;
}

.dropdown-menu2 {
    padding-left: 0;
}

.dropdown-menu2 li {
    padding-bottom: 16px;
    display: block;
    float: none;
}


/* Media query for mobile screens */
@media (max-width: 768px) {
    /* Stack the menu items vertically for mobile */
    .menu-list-links {
        display: none; /* Hide the menu by default */
        flex-direction: column;
        gap: 10px;
        background-color: #333;
        position: fixed;
        top: 100px;
        right: 0;
        width: 100%;
        height: calc(100vh - 60px);
        justify-content: flex-start;
        padding: 20px;
        transition: 0.3s ease-in-out;
    }

    /* Fullscreen dropdown menu styles */
    .menu-list-item {
        /* position: relative; */
        width: 100%;
        text-align: left;
        transition: all 1s ease-in-out;
    }

    .dropdown-menu{
        background-color: #333;
        opacity: 1;
        visibility: visible;
        top: 25px;
    }

    /* Dropdowns should be visible when active */
    /* .menu-list-item:hover .dropdown-menu,
    .menu-list-item.active .dropdown-menu {
        opacity: 1;
        visibility: visible;
        position: sticky;
        max-height: 350px;
    } */

    /* Hamburger button */
    .hamburger {
        display: block;
        cursor: pointer;
        font-size: 24px;
        top: 15px;
        right: 20px;
        z-index: 100;
        color: white;
    }

    .menu-list-links.active {
        display: flex;
    }

    .showArrow {
        display: block;
    }

    .hover-underline-animation {
        display: flex;
        gap:5px;
        width: 50%;
    }

    .dropdown-item {
        color:white;
    }

    .submenu {
        /* position: relative; */
        left: 0;
    }
}