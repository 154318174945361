.dialog-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dialog-container-content p {
    font-family: 'Inter,sans-serif';
    font-size: 1.375rem;
    font-weight: 400;
    line-height: 1.45455;
}

.image-position {
    display: flex;
    justify-content: center;
    align-items: center;
}