.navbar {
  position: fixed;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  gap: 100px;
  top: 0;
  z-index: 999;
  width: 100%;
  transition: 0.3s ease-in-out;
}

.navbar.colorChange {
  transition: 0.3s ease-in-out;
  background-color: rgb(29, 29, 29);
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
  padding-left: 10px;
}

.navbar-menu-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding-right: 50px;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  /* text-decoration: none;
    font-size: 2rem; */
  display: flex;
  align-items: center;
}

.logo {
  width: 200px;
  height: 160px;
}

.logo-name {
  color: white;
  font-family: "Lobster Two", sans-serif;
}

#langauge-select {
  color: white;
  background-color: rgba(0, 0, 0, 0.2);
}

#language-select-color {
  color: white;
  border-color: rgb(123, 123, 123) !important;
  background-color: rgb(30, 30, 30);
}

.country-flags {
  height: 20px;
  width: 20px;
}

.language-item {
  position: relative;
  gap: 10px;
}

.donation-button-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;

  z-index: 999;
  top: 25%;
  right: 0;
}

.donation-button-style {
  width: 150px;
  height: 76px;
  line-height: 78px;
  font-size: 20px;
  font-family: "Lobster Two", sans-serif;
  background: linear-gradient(45deg, transparent 5%, #ff0101 5%);
  border: 0;
  color: #fff;
  letter-spacing: 3px;
  box-shadow: 6px 0px 0px #00e6f6;
  outline: transparent;
  position: relative;
  cursor: pointer;
  transition: 0.3s;
}

.donation-button-style:hover {
  background: linear-gradient(45deg, transparent 5%, #f86262 5%);
  transition: 0.3s;
  height: 76px;
  width: 200px;
}

@media (max-width: 768px) {
  .logo {
    width: 100px;
    height: 70px;
  }

  .donation-button-container {
    height: 50px;
  }
  .donation-button-style {
    width: 90px;
    height: 45px;
    line-height: 35px;
    font-size: 15px;
  }

  .navbar{
    gap: 30px;
  }
}
