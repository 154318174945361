/* src/Balloon.css */
@keyframes float {
    0% {
      bottom: 80%; /* Start below the bottom */
    }
    100% {
      bottom: 110%; /* End above the top */
    }
  }
  
  .balloon {
    height: 125px;
    width: 105px;
    border-radius: 75% 75% 70% 70%;
    transition: opacity 500ms;
    position: absolute;
    animation-name: float;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    z-index: 100; /* Ensure balloons are in front */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add box-shadow */
    margin: 1px 0 0 2px;
  }

  .balloon:before {
    content: "";
    height: 75px;
    width: 1px;
    padding: 1px;
    background-color: #FDFD96;
    display: block;
    position: absolute;
    top: 125px;
    left: 0;
    right: 0;
    margin: auto;
  }

  .balloon:after {
    content: "▲";
    text-align: center;
    display: block;
    position: absolute;
    color: inherit;
    top: 114px;
    left: 0;
    right: 0;
    margin: auto;
}