.teams-container {
    display: flex;
    padding-left: 20%;
    padding-right: 5%;
    padding-top: 10rem;
    padding-bottom: 4rem;
    gap: 20px;
}

.teams-title {
    /* display: flex;
    align-items: center;
    justify-content: center; */
    text-align: center;
}

.teams-title h2 {
    font-size: 50px;
}

.teams-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 700px;
}

.teams-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 700px;
}

.teams-image img {
    width: 500px;
}

.teams-members {
    padding-top: 100px;
}


.team-members-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 50px;
}

.team-row {
    display: flex;
    flex-direction: row;
    gap:20px;
    max-height: fit-content;
    justify-content: center;
}

.member{
    width: calc(100% / 5);
    margin-top: 55px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
    /* height: 400px; */
}

.square {
    height: 150px;
}
.square img {
    width: 150px;
    border-radius: 100%;
    overflow: hidden;
}
.member-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
}

.member-info h4{
    font-size: 24px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    letter-spacing: 0.11em;
    line-height: 1.3em;
    text-transform: uppercase;
    text-align: center;
}

.member-info p {
    text-align: center;
    font-size: 22px;
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: normal;
    letter-spacing: 0.11em;
    line-height: 1.3em;
    text-transform: uppercase;
    color: #d68231;
}

@media (max-width: 768px) {
    .team-row {
        flex-direction: column;
        align-items: center;
    }

    .team-members-container{
        padding-left: 0;
    }

    .member-info h4{
        text-align: center;
    }
}