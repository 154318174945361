.panel {
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    background-color: #f9f9f9;
    overflow: hidden; /* Hide content overflow */
    transition: max-height 0.3s ease-out; /* Add transition for max-height */
  }
  
  .panel-title {
    padding: 15px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    font-family: 'Basiercircle', sans-serif;
  }
  
  .panel-content {
    max-height: 0; /* Initially hide content */
    overflow: hidden; /* Hide overflow */
    transition: max-height 0.3s ease-out; /* Add transition for max-height */
  }
  
  .panel.active .panel-content {
    max-height: 850px; /* Set max-height to a large value to allow content expansion */
  }

  .panel-content-position {
    padding: 15px;
    font-family: 'Basiercircle', sans-serif;
    line-height: 1.45455;
    font-size: 1rem;
  }

  /* @media (max-width:768px) { 
    .panel.active .panel-content {
      max-height: 850px;

    }

  } */
  

  
  