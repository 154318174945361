.internship-container {
    display: flex;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 10rem;
    padding-bottom: 8rem;
    gap: 20px;
}

.internship-container-content {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    width: 100%;
    padding-top: 3.375rem; 
    padding-bottom: 3.375rem; 
}

.internship-container-content h2 {
    letter-spacing: -.03em;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 4rem;
    font-weight: 500;
    font-family: 'Basiercircle',sans-serif
}

.internship-container-content p{
    font-family: Inter,sans-serif;
    font-size: 1.375rem;
    font-weight: 400;
    line-height: 1.45455;
}

.internship-container-image {
    width: 100%;
}

.internship-container-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 100% 50%;
}

.internship-album {
    padding-top: 8rem;
    padding-bottom: 8rem;
}

.questions-section {
    margin-top: 100px;
    margin-left: 30px;
    display: flex;
    flex: row;
    justify-content: center;
    gap: 50px;
}

.questions-container {
    display: flex;
    flex-direction: column;
}
/* 
.questions-image-container {
    width: 100%;
} */

.questions-image {
    width: 500px;
}

.internship-questions-section {
    padding-top: 8rem;
    padding-bottom: 6rem;
}

.questions-headline {
    display: flex;
    justify-content: center;
    align-items: center;
}

.questions-headline h2 {
    letter-spacing: -.03em;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 4rem;
    font-weight: 500;
    font-family: 'Basiercircle', sans-serif;
}

.contact-section {
    margin-top: 8rem;;
    background-color: #f5f5f5;;
    padding-top: 10px;
    padding-bottom: 30px;
    margin-bottom: 8rem;
    text-align: center;
    width: 700px;
  }
  
  .contact-info {
    display: flex;
    justify-content: center;
    gap: 150px;
    margin-top: 30px;
  }
  
  .contact-item {
    max-width: 300px;
  }
  
  .contact-item h3 {
    font-size: 20px;
    margin-bottom: 10px;
    font-family: 'Basiercircle', sans-serif;
  }
  
  .contact-item p {
    font-size: 16px;
    line-height: 1.6;
    font-family: 'Basiercircle', sans-serif;
  }

  .contact-section-position {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width:768px) {
    .internship-container {
        flex-direction: column;
        text-align: center;
        padding-top: 1rem;
    }

    .internship-album {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .questions-section {
        flex-direction: column-reverse;
        margin-left: 0px;
    }
    .questions-image {
        width: 350px;
    }
}