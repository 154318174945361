.page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 5rem;
    padding-bottom: 10rem;
}

.page-default-banner {
    margin-top: 1rem;
    position: relative;
    width: 100vw;
    height: 500px;
    object-fit: cover;
    object-position: center;
}

.row h1 {
    margin-bottom: 0;
}

.row {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 1rem;
    margin-left: 10rem;
    margin-right: 1rem;
}

.color-big-heading {
    position: relative;
    z-index: 99;
    /* bottom: -100px;
    right: 400px; */
}

.color-big-heading:after {
    background: #fdc221;
    bottom: 4px;
    content: "";
    height: 20px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: -1;
}

.color-big-heading {
    color:white;
}

.breadcrumb-wrapper {
    display: flex;
    /* padding-top: 10px; */
    list-style: none;
    color:white;
    padding-left: 0;
    margin-top: 0;
}

.breadcrumb-item {
    padding: 0 10px 0 0;
}

.section-container {
    margin-right: auto;
    margin-left: auto;
}

.section-row {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 1rem;
    padding-left: 1rem;
    margin-top: 3rem;
}

.section-col1 {
    width: 66%
}

.section-col2 {
    width: 34%;
}

.section-image{
    overflow: hidden;
    position: relative;
    height: 500px;
    border-radius: 10px;
}

.section-image img{
    height: 500px;
    width: 100%;
    /* object-fit: cover;
    object-position: center; */
    transition: all .4s ease-in-out;
}
.section-image img:hover {
    transform: scale(1.3);
}

.section-text {
    padding-top: 30px;
}

.section-text-title{
    font-size: 36px;
    line-height: 50px;
}

.section-text-p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #818090;
}

.section-text-p ul {
    color:red;
}

.section-text-p ul li {
    padding-top: 15px;
    color: #818090;
}

.section-text-p ul li::marker {
    color:red;
}

.image-row {
    display: flex;
    justify-content: center;
    gap:10px;
}

.image-col {
    width: 50%;
}

.image-col img {
    width: 100%;
}

.pd-t-40 {
    padding-top: 40px
}

.row-article{
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-left: 15px;
}

.article-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    

    /* margin: 28px 0 0; */
    border-top: 1px solid rgba(238, 238, 238, .2);
    padding-top: 13px;
    color: #fff;
    text-transform: uppercase;

    
    font-size: 14px;
    font-weight: 700;
}

.article {
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.article-data {
    margin: 0 1.96em 0 0;
}

.article-width {
    width: 1200px;
}

.article-title {
    position: relative;
    z-index: 1;
    margin: 8px 0 0;
    line-height: 1.286;
    color: #fff;
    font-size: 48px;
}

.article-content-row {
    display: flex;
    gap: 30px
    /* justify-content: center; */
    /* align-items: center;
    padding-right: 1rem;
    padding-left: 1rem; */
   
}

.article-image-style {
    width: 800px;
}

.sidebar_widget:is(.has_border) {
    border: 2px solid #f3f3f3;
}

.sidebar_widget {
    padding: 38px 40px 40px;
}

.mb-40 {
    margin-bottom: 40px;
}

.feed_widget_img {
    -moz-box-flex: 0;
    flex: 0 0 auto;
    margin-right: 19px;
}

.single_feed_widget{
    display: flex;
    align-items: center;
}

.single_feed_widget a {
    color:black;
}

.feed_widget_img img {
    max-width: 80px;
}

.sidebar_title_text:is(.has_border) {
    position: relative;
    padding-bottom: 20px;
    border-bottom: 1px solid #f1f1f1;
    margin-bottom: 30px;
}

.single_feed_widget:is(.has_border) {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #f1f1f1;
}

.feed_widget_title:is(.theme-1):hover {
    color: #f15b43;
}

.feed_widget_date {
    text-decoration: none;
    font-size: 14px;
}

.article-frame {
    width:800px;
    height:800px;
}

@media (max-width: 768px)  {
    .row {
        margin-left: 20px;
        margin-top: 2rem;
    }

    .row h1{
        font-size: 1.50rem;
    }

    .sidebar_widget {
        padding: 5px 5px 5px;
    }

    .sidebar_widget:is(.has_border) {
        border: 0 solid #f3f3f3;
    }

    .breadcrumb-item {
        font-size: 1rem;
    }

    .color-big-heading:after {
        height: 10px;
    }

    .article-width {
        width: 100%;
    }

    .article-frame {
        width:300px;
        height:600px;
    }

    .section-row {
        flex-direction: column;
        width: 100%;
        padding-right: 0;
        padding-left: 0;
        margin-top: 0;
        align-items: unset;
    }

    .section-image {
        height: 255px;
    }

    .section-image img{
        height: 250px;
    }

    .section-col1 {
        width: 100%;
        padding: 10px;
    }

    .page-container {
        align-items: normal;
    }

    .section-content {
        text-align: center;
    }

    .section-text-title {
        text-align: center;
    }

    .article-content-row {
        flex-direction: column;
    }

    .article-image-style {
        width: 300px;
    }

    .dialog-container-content {
        display: flex;
        /* align-items: center; */
        justify-content: center;
        flex-direction: column;
    }
    
}
