.donations-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.donations-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap:wrap;
    gap: 50px;
}

.donations-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.section-title {
    font-size: 50px;
    line-height: 60px;
    font-weight: 700;
    text-align: center;
}

.service-title{
    font-size: 20px;
    line-height: 20px;
    font-weight: 800;
    text-transform: capitalize;
    margin-bottom: 23px;
}

.service-text {
    line-height: 29px;
    text-align: center;
}

.service-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
}

.service-icon{
    display: inline-block;
    padding: 12px 15px;
    position: relative;
    margin-bottom: 27px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e5f5f6;
    border-radius: 50%;
}

.icon-style{
    width: 65px;
}

.donation-button {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

