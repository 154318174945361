.footer-container{
    width: 100%;
    height: 800px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    z-index: -1;
    display: flex;
    /* align-items: center; */
    justify-content: center;
}

.footer-container::before {
    position: absolute;
    background-color: rgba(52, 54, 56, 0.96);
    width: 100%;
    content: "";
    height: 800px;
}


.footer-row {
    width: 75%;
    position: absolute;
    z-index: 99;
    padding-top: 3rem;
    margin-left: 150px;
    margin-right: 100px;
    margin-top: 100px;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: start;
    gap: 50px;
}

.col {
    display: flex;
    flex-direction: column;
}

.col-links {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.title {
    font-size: 24px;
    color: #fff;
    font-weight: 800;
    text-transform: capitalize;
    margin-bottom: 23px;
}

.info-style p {
    color: #bfbfbf;
    font-size: 16px;
    line-height: 38px;
    font-weight: 400;
    position: relative;
}

.col-follow{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.contact-hover-underline-animation {
    display: inline-block;
    text-align: justify;
    color: #bfbfbf;
    font-size: 16px;
    white-space: nowrap;
}

.contact-hover-underline-animation::after {
    content: '';
    display: block;
    width:auto;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #bfbfbf;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

.contact-hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

@media (max-width: 768px) {
    .footer-row{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 100px;
        margin-right: 100px;
    }

    .footer-container{
        height: 1300px;
    }

    .footer-container::before{
        height: 1300px;
    }

    .col-links {
        align-items: center;
        justify-content: center;
    }
}