.offer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 50px;
    gap: 70px;
}

.row-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* flex-wrap: wrap; */
    gap: 70px;
}

.section-title {
    font-size: 50px;
    line-height: 60px;
    font-weight: 700;
}

.col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.blog-img {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 450px;
    margin-bottom: 10px;
}

.blog-img img {
    max-width: 450px;
    height: 350px;
}

.blog-inner-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 44px 45px 50px 50px;
    background-color: #f2f0ec;
    max-width: 450px;
    height: 400px;
}

.blog-title {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 40px;
}

.blog-desc {
    line-height: 29px;
    text-align: center;
    /* padding-bottom: 40px; */
}

@media (max-width:768px) {
    .row-container{
        flex-direction: column;
    }

}

