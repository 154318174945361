.container-style{
    height: 1000px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

/* One Way */

.banner-text {

    font-family: 'Noto Sans JP', sans-serif;

    margin: 50px auto 0;
    padding: 0;
    width: 100%;
    text-align: center;
    font-size: 5rem;
    line-height: 107px;
    color:#fff;
    font-weight: 900;
}

.intro {
    margin-top: 400px;
    margin-left: 50px;
    height: 500px;
    /* width: 850px; */
}

.find-more-button {
    background-color: white !important;
    color: black !important;
    font-family: 'Poppins', sans-serif !important;
}

.mouse-container {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 32px;
}

.mouse {
    width: 35px;
    height: 64px;
    border: 4px solid white;
    border-radius: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: start;
    padding: 2;
}

.mouse-animation {
    width: 3px;
    height: 3px;
    border-radius: 9999px;
    border: 4px solid white;
    margin-bottom: 0.25rem;
}

.heroSlidder {
    z-index: 1;
}

@keyframes circularBounce {
    0% {
      transform: translate(0, 0);
    }
    25% {
      transform: translate(50px, -50px)
    }
    50% {
      transform: translate(100px, 0);
    }
    75% {
      transform: translate(50px, 50px);
    }
    100% {
      transform: translate(0, 0);
    }
  }

.banner30{
    display: flex;
    align-items: center;
    justify-content: center;
    animation: circularBounce 15s infinite ease-in-out;
}

.hero-slider-style {
    width: 100%;
    height: 500px; /* Default height for larger screens */
}

@media (max-width: 768px) {
    .intro {
        margin-top: 135px;
        margin-left: -100px;
        height: 250px;
        width: 425px;
    }
    .banner-text {
        font-size: 1rem;
    }
    .hero-slider-style {
        height: 300px; /* Decrease the slider height */
    }
    .banner-text {
        font-family: 'Noto Sans JP', sans-serif;

        margin-left: 100px;
        padding: 0;
        width: 80%;
        /* text-align: center; */
        font-size: 1rem;
        line-height: 35px;
        color:#fff;
        font-weight: 900;
    }

    .banner30 img{
        display: none;
    }

    .mouse {
        width: 18px;
        height: 28px;
        border: 2px solid white;
        border-radius: 1.5rem;
    }
}
