.padding-top-100 {
    padding-top: 100px;
}

.contact-container {
    padding-right: 1.75rem;
    padding-left: 1.75rem;
    margin-right: auto;
    margin-left: auto;
}

.contact_item {
    background: #fff;
    border-radius: 8px;
    box-shadow: 8px 10px 24px #0000001a;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    padding: 30px 40px;
}

.vol-width {
    width: 50%;
}

.flex-row{
    display: flex;
    align-items: center;
    gap:20px;
}

.contact-row{
    display: flex;
    align-items: flex-start;
    gap:20px;
}

.icon-styled{
    background-color:rgba(78, 107, 235, 0.801);
    transform: scale(1.8);
    color:white;
    border-radius: 100%;
}

.contact-container-title {
    text-align: center;
    padding-bottom: 1rem;
    font-size: 2rem;
}

@media (max-width: 768px) { 
    .vol-width {
        width: 100%;
    }
}