.image-slider {
  position: relative;
  width: 800px; /* Adjust as needed */
  height: 500px;
  margin: 0 auto;
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 100%;
  /* padding: 10px 18px; */
  width: 30px;
  height: 30px;
  font-size: 18px;
  cursor: pointer;
  
}

.nav-button.prev {
  left: 0;
}

.nav-button.next {
  right: 0;
}

.image-slider img {
  width: 800px; /* Ensures images fill their containers */
  height: 500px;
  object-fit: cover; /* Maintains aspect ratio and fills the container */
}


@media (max-width: 768px)  {
  .image-slider {
    position: relative;
    width: 300px; /* Adjust as needed */
    height: 300px;
    margin: 0;
  } 

  .image-slider img {
    width: 300px; /* Ensures images fill their containers */
    height: 300px;
    object-fit: cover; /* Maintains aspect ratio and fills the container */
  }
}
